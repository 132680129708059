import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "../views/HomePage.vue";
import store from "../store";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test.vue"),
  },
  {
    path: "/font1",
    name: "font1",
    component: () => import("../views/font1.vue"),
  },
  {
    path: "/font2",
    name: "font2",
    component: () => import("../views/font2.vue"),
  },
  {
    path: "/font3",
    name: "font3",
    component: () => import("../views/font3.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 在router的beforeEach钩子中
router.beforeEach((to, from, next) => {
  const isFirstVisit = sessionStorage.getItem("isFirstVisit");
  if (!isFirstVisit) {
    // 首次访问时，不启动加载动画
    sessionStorage.setItem("isFirstVisit", "false");
  } else {
    // 非首次访问，启动加载动画
    store.commit("setLoading", true); // 开始加载
  }
  next();
});

let loadingTimer: any;

router.afterEach(() => {
  clearTimeout(loadingTimer); // 清除之前的计时器
  loadingTimer = setTimeout(() => {
    store.commit("setLoading", false); // 至少2秒后结束加载
  }, 700); // 设置最小加载时间为2秒
});

export default router;
