<template>
  <div class="flex flex-col bg-gray-100 ls">
    <TopNavBar id="top-nav-bar" />
    <div id="banner" class="relative flex-1 overflow-hidden">
      <img
        src="@/assets/iStock-1092303414.jpg"
        alt="Full Screen Image"
        class="w-full object-cover"
        style="height: 460px"
      />
      <div
        class="absolute top-56 left-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:w-2/3 w-full text-black px-4"
      >
        <div class="text-left mb-4 lg:mb-6 xl:mb-8">
          <h1
            class="font-bold font-tw-cen-mt-bold mt-16 text-xl md:text-2xl lg:text-3xl xl:text-4xl"
            style="letter-spacing: 4px"
          >
            Inspire for your life
          </h1>
        </div>
        <div class="md:flex items-center md:justify-between">
          <div class="mr-2">
            <p
              class="text-base xl:text-xl font-bold mb-3 lg:mb-4 xl:mb-6"
              style="letter-spacing: 4px"
            >
              可能性をあきらめない
            </p>
            <p class="xl:text-base text-sm mb-1">踏み出したその一歩を</p>
            <p class="xl:text-base text-sm">カタチにしたい</p>
          </div>
          <div class="mt-6 md:mt-0 md:ml-2 text-gray-500">
            <p class="xl:text-base text-sm mb-1">Inspire for your lifeは</p>
            <p class="xl:text-base text-sm">
              公務員出身者への起業共創サポートサービスです
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- <ScrollingImages class="sticky top-20" /> -->
    <!-- ------------------------------------------------------------------------------------------- -->
    <div
      class="relative lg:flex lg:justify-end w-full mt-10 md:mt-20 mb-10 px-3 md:px-20"
    >
      <div
        class="bg-white shadow-sm rounded-sm py-6 md:py-10 px-8 md:px-12 lg:px-24 w-full"
      >
        <div class="flex justify-start md:justify-end mb-4 lg:mb-10">
          <img
            src="@/assets/1.png"
            alt="Icon"
            class="h-8 lg:h-12 xl:h-16 -mr-0 md:-mr-2 lg:-mr-4 xl:-mr-5"
          />
        </div>
        <h2
          class="text-left md:text-right font-bold text-base lg:text-xl xl:text-2xl mb-3 lg:mb-8 xl:mb-10"
        >
          「今までなかった」を創りたい
        </h2>
        <div
          class="text-left md:text-right text-xs xl:text-sm mb-3 lg:mb-5 xl:mb-6 leading-5 lg:leading-6 xl:leading-7"
        >
          <p>私たちは自身の経験を基に、</p>
          <p>求められているサービスとは何かを考えました。</p>
        </div>
        <p
          class="text-left md:text-right font-bold text-sm xl:text-base mb-3 lg:mb-5 xl:mb-6"
        >
          私たちだから共感できること
        </p>
        <div
          class="text-left md:text-right text-xs leading-5 lg:leading-6 xl:leading-7"
        >
          <p>同じ境遇にいた私たちだからこそ、その悩みや葛藤に寄り添える。</p>
          <p class="mr-1">そして、私たち自身の経験と実績が少しでも</p>
          <p>
            誰かの役に立てればと思い“Inspire for your life”を立ち上げました。
          </p>
        </div>
        <img
          src="@/assets/top1.png"
          alt="Full Screen Image"
          class="w-auto lg:hidden top-area-1-img"
        />
        <div class="h-10"></div>
      </div>
      <img
        src="@/assets/top1.png"
        alt="Full Screen Image"
        class="w-auto lg:absolute lg:flex hidden top-area-1-img"
      />
    </div>
    <!-- --------------------------------------------Concept----------------------------------------------- -->
    <div class="relative flex w-full mt-6 md:mt-10 lg:mt-40 pl-3 md:pl-24">
      <div class="rounded-sm pt-8 lg:px-4 xl:px-10 w-full">
        <h4 class="text-left font-bold text-base xl:text-xl mb-2 text-gray-400">
          コンセプト
        </h4>
        <h2
          id="concept"
          class="text-left font-bold text-2xl lg:text-3xl xl:text-4xl mb-8 lg:mb-14 font-bahnschrift-semiLight"
        >
          Concept
        </h2>
        <div class="flex items-center justify-start mb-6 lg:mb-10">
          <div
            class="text-left text-sm lg:text-base xl:text-xl leading-6 lg:leading-7 mr-16 text-gray-400"
          >
            <p>私たちの「経験」で</p>
            <p>あなたに寄り添いたい</p>
          </div>
          <div class="flex justify-end">
            <img src="@/assets/7.png" alt="Icon" class="h-12 lg:h-14 xl:h-16" />
          </div>
        </div>
        <div
          class="text-left text-xs xl:text-sm leading-6 mb-6 lg:mb-12 xl:mb-24 font-kosugi-gothic"
        >
          <div class="hidden sm:block">
            <p>
              求められるニーズの多様化、行政改革に伴う人員・予算の削減、さらに業務量の増加など、
            </p>
            <p class="mb-3 lg:mb-6 xl:mb-8">
              公務員を取り巻く環境は大きく変化しています。
            </p>
          </div>
          <div class="sm:hidden">
            <p>求められるニーズの多様化、行政改革に伴う人員・予算の削減、</p>
            <p class="mb-3 lg:mb-6 xl:mb-8">
              さらに業務量の増加など、公務員を取り巻く環境は大きく変化しています。
            </p>
          </div>
          <p>社会環境の変化のスピードが速い今、</p>
          <p class="mb-3 lg:mb-6 xl:mb-8">
            様々な場面で「自分はこのままで良いのだろうか」と想うことが強まることもあるでしょう。
          </p>
          <p>公務員という立場や組織を知り尽くしている私たちだからこそ、</p>
          <p>
            あなたと同じ目線に立って共に考え、抱える想いに寄り添いながら、踏み出すその一歩を、様々な形でサポートします。
          </p>
        </div>
        <div class="text-left md:flex items-center md:justify-start">
          <div class="flex">
            <p
              class="md:mt-0 mt-3 text-3xl lg:text-5xl xl:text-6xl mr-6 star-item-text-red font-gt-walsheim-pro"
              style="letter-spacing: 0.1px"
            >
              01
            </p>
            <div
              class="text-sm lg:text-base xl:text-xl leading-snug mr-3 md:mr-10"
            >
              <p>思いを言葉に</p>
              <p>行動に</p>
              <p>そして形に</p>
            </div>
          </div>

          <div
            class="md:mt-0 mt-3 text-xs xl:text-sm leading-4 lg:leading-6 text-gray-400 font-kosugi-gothic"
          >
            <p>独立・起業を目指すあなたの思いを具体的な言葉と行動に、</p>
            <p class="mb-2 xl:mb-4">
              そして具体的な形にしていくことに焦点を当てています。
            </p>
            <p>
              このサービスは、<span class="inline sm:block"
                >あなたの思いを実現するためのサポートを提供します。</span
              >
            </p>
          </div>
        </div>
        <div class="text-left text-sm flex justify-between mb-10 flex-wrap">
          <div class="min-w-72 min-w xl:min-w-96 mt-12 md:mt-20">
            <div class="flex items-center mb-3 md:mb-6 xl:mb-8">
              <p
                class="text-3xl xl:text-4xl mr-6 star-item-text-red font-gt-walsheim-pro"
              >
                02
              </p>
              <div
                class="text-sm xl:text-base leading-snug lg:min-w-16 xl:min-w-48"
              >
                <p>共に成長し</p>
                <p>高め合う</p>
              </div>
            </div>
            <div
              class="text-xs xl:text-sm lg:leading-5 xl:leading-6 text-gray-400 font-kosugi-gothic"
            >
              <p>あなたの独立・起業が成功するための</p>
              <p>知識とスキルを提供します。</p>
              <p>そして、常に新しい情報を共有します。</p>
            </div>
          </div>
          <div class="min-w-72 min-w xl:min-w-96 mt-12 md:mt-20">
            <div class="flex items-center mb-3 md:mb-6 xl:mb-8">
              <p
                class="text-3xl xl:text-4xl mr-6 star-item-text-red font-gt-walsheim-pro"
              >
                03
              </p>
              <div
                class="text-sm xl:text-base leading-snug lg:min-w-16 xl:min-w-48"
              >
                <p>挑戦を</p>
                <p>チャンスに</p>
              </div>
            </div>
            <div
              class="text-xs xl:text-sm lg:leading-5 xl:leading-6 text-gray-400 font-kosugi-gothic"
            >
              <p>あなたの挑戦をチャンスに変える。</p>
              <p>
                ビジネスを立ち上げるまで、<span class="sm:block"
                  >持続的・継続的に共創します。</span
                >
              </p>
            </div>
          </div>
          <div class="min-w-72 min-w xl:min-w-96 mt-12 md:mt-20">
            <div class="flex items-center mb-3 md:mb-6 xl:mb-8">
              <p
                class="text-3xl xl:text-4xl mr-6 star-item-text-red font-gt-walsheim-pro"
              >
                04
              </p>
              <div
                class="text-sm xl:text-base leading-snug lg:min-w-16 xl:min-w-48"
              >
                <p>一人ひとりの</p>
                <p>ニーズに合わせて</p>
              </div>
            </div>
            <div
              class="text-xs xl:text-sm lg:leading-5 xl:leading-6 text-gray-400 font-kosugi-gothic"
            >
              <p>あなたのニーズと目標に合わせて</p>
              <p>カスタマイズが可能。</p>
              <p>どのプランがあなたにとって</p>
              <p>最も魅力的であるかを共に探ります。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------------------------------------☆☆☆☆☆☆☆☆☆----------------------------------------------- -->
    <div
      class="animated-section rectangle-all relative w-full bg-red-400 overflow-hidden"
    >
      <div
        class="tracking-widest absolute w-full top-1/3 left-1/2 transform -translate-x-1/2 px-4 md:px-10 z-10 text-center text-white"
      >
        <p class="text-xl md:text-2xl lg:text-3xl xl:text-4xl mb-14">
          あなたの一番星のような存在でありたい
        </p>
        <p class="text-xs md:text-sm xl:text-base mb-1">
          どういう生き方が自分に合っているのか。
        </p>
        <p class="text-xs md:text-sm xl:text-base mb-4">
          病気や怪我による治療や療養、育児や介護、学び直しなど、<span
            class="block sm:inline"
            >働きながら多様な背景を持つ方がいます。</span
          >
        </p>
        <p class="text-xs md:text-sm xl:text-base mb-4">
          働き方の自由度を高めることができる選択肢の一つとして、<span
            class="block sm:inline"
          >
            独立・起業があります。</span
          >
        </p>
        <p class="text-xs md:text-sm xl:text-base">
          働く時間、働く場所の自由度を上げ、多様な背景と仕事の<span
            class="block sm:inline"
            >バランスを調整していくことが可能です。</span
          >
        </p>
        <p class="text-xs md:text-sm xl:text-base">
          私たちは、これまでの経験や実践に基づいた、今欲しい、<span
            class="block sm:inline"
            >すぐに使える具体的な知識と手法をご提案いたします。</span
          >
        </p>
      </div>
      <div class="bg-gray-100 rectangle top-left"></div>
      <div class="bg-gray-100 rectangle top-right"></div>
      <div class="bg-gray-100 rectangle bottom-left"></div>
      <div class="bg-gray-100 rectangle bottom-right"></div>
    </div>
    <!-- --------------------------------------------OUR SERVICE----------------------------------------------- -->
    <div class="animated-section2 w-full">
      <div
        class="text-center text-gray-500 text-3xl lg:text-4xl mt-20 mb-10 font-bahnschrift-semiLight"
      >
        OUR SERVICE
      </div>
      <div
        class="bg-white w-full lg:w-4/5 xl:w-2/3 py-10 px-0 md:px-32 xl:px-40 relative flex items-center mx-auto"
      >
        <div class="relative grid grid-cols-2 w-full">
          <div
            class="absolute left-1/2 transform -translate-x-1/2 border border-gray-400 h-full"
          ></div>
          <!-- 水平线 -->
          <div
            class="absolute top-1/2 transform -translate-y-1/2 border border-gray-400 w-full"
          ></div>
          <div class="p-8 box mouseMove1" @click="navigateToService(0)">
            <p
              class="text-gray-500 font-kosugi-gothic text-xs md:text-sm lg:text-base"
            >
              ヒアリング
            </p>
            <div class="mt-16 w-full">
              <img
                src="@/assets/2.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto original-icon"
              />
              <img
                src="@/assets/2_w.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto white-icon"
                style="display: none"
              />
            </div>
          </div>
          <div class="p-8 box mouseMove2" @click="navigateToService(1)">
            <p
              class="text-gray-500 font-kosugi-gothic text-xs md:text-sm lg:text-base"
            >
              プランニング
            </p>
            <div class="mt-16 w-full">
              <img
                src="@/assets/8.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto original-icon"
              />
              <img
                src="@/assets/8_w.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto white-icon"
                style="display: none"
              />
            </div>
          </div>
          <div class="p-8 box mouseMove3" @click="navigateToService(2)">
            <p
              class="text-gray-500 font-kosugi-gothic text-xs md:text-sm lg:text-base"
            >
              独立サポート
            </p>
            <div class="mt-16 w-full">
              <img
                src="@/assets/5.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto original-icon"
              />
              <img
                src="@/assets/5_w.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto white-icon"
                style="display: none"
              />
            </div>
          </div>
          <div class="p-8 box mouseMove4" @click="navigateToService(3)">
            <p
              class="text-gray-500 font-kosugi-gothic text-xs md:text-sm lg:text-base"
            >
              アフターサポート
            </p>
            <div class="mt-16 w-full">
              <img
                src="@/assets/6.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto original-icon"
              />
              <img
                src="@/assets/6_w.png"
                alt="Icon"
                class="h-8 md:h-12 lg:h-16 mx-auto white-icon"
                style="display: none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------------------------------------------------- -->
    <div class="w-full mt-20 mb-32 px-0 md:px-12 xl:px-32">
      <div class="bg-white shadow-sm rounded-sm pt-20 xl:pt-32 pb-40 w-full">
        <div class="md:flex md:justify-start items-center">
          <div class="left-part">
            <div class="flex lg:mb-4 xl:mb-10">
              <div
                class="mr-5 md:mr-12 text-vertical font-montserrat-light text-gray-600"
              >
                <p class="text-xs lg:text-sm xl:text-base">
                  Inspire for your life
                </p>
                <p class="text-sm lg:text-base xl:text-xl mt-3">STAR ITEM</p>
              </div>
              <div class="square star-item-bg-red relative">
                <div
                  class="square absolute bottom-3 left-3 bg-white border border-white img-l"
                ></div>
              </div>
            </div>
          </div>
          <div
            class="right-part font-bahnschrift-semiLight md:my-0 my-4 md:ml-0 ml-4"
          >
            <p class="star-item-text-red font-bold text-sm">
              WE PROVIDE ONGOING
            </p>
            <p class="star-item-text-red font-bold text-sm">
              SUPPORT FOR YOUR STARTUP
            </p>
          </div>
        </div>
        <div
          class="bottom md:flex items-center ml-4 md:ml-40 lg:ml-52 xl:ml-60 mb-24 md:mb-32 lg:mb-40"
        >
          <p
            class="text-base lg:text-xl xl:text-3xl mr-0 md:mr-4 lg:mr-6 xl:mr-12 text-gray-500 font-bahnschrift-semiLight"
          >
            Tomohiro Fujii
          </p>
          <div
            class="text-xs xl:text-sm leading-6 xl:leading-8 font-kosugi-gothic text-gray-500"
          >
            <p>スターアイテム株式会社代表取締役社長</p>
            <p>スターアイテム行政書士事務所代表</p>
          </div>
        </div>
        <!-- <div
          class="right-part font-bahnschrift-semiLight md:text-left mr-4 md:mr-0 mb-24 md:mb-32 lg:mb-40"
        >
          <p
            class="star-item-text-red font-bold text-xs my-2 xl:text-sm text-center"
          >
            WE PROVIDE ONGOING
          </p>
          <p
            class="star-item-text-red font-bold text-xs my-2 xl:text-sm text-center"
          >
            SUPPORT FOR YOUR STARTUP
          </p>
        </div> -->
        <div class="flex justify-end items-center mb-20">
          <div>
            <div class="flex mb-8">
              <div
                class="items-center lg:mt-10 xl:mt-12 lg:mr-6 xl:mr-10 text-right"
              >
                <p
                  class="text-base md:text-xl xl:text-3xl mb-8 lg:mb-10 xl:mb-16 text-gray-500 font-bahnschrift-semiLight"
                >
                  Rena Takahashi
                </p>
                <div
                  class="text-xs leading-7 xl:leading-9 text-gray-500 font-kosugi-gothic"
                >
                  <p>スターアイテム株式会社副社長</p>
                  <p>スターアイテム行政書士事務所補助者</p>
                  <p>sabadgud zanika代表</p>
                </div>
              </div>
              <div class="square2 star-item-bg-red mx-6 md:mx-10"></div>
              <div
                class="md:mr-10 text-vertical2 font-montserrat-light text-gray-600 -mr-20"
              >
                <p class="text-xs lg:text-sm xl:text-xl">
                  Inspire for your life
                </p>
                <p
                  class="text-sm md:text-base lg:text-xl xl:text-2xl mt-2 lg:mt-4 xl:mt-6 ml-1"
                >
                  STAR ITEM
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-start md:justify-items-center mb-20 lg:mb-32 xl:mb-40"
        >
          <div class="text-left min-w-40 mb-14 md:mb-0 ml-6 md:ml-0">
            <div class="square3 star-item-bg-red mb-5"></div>
            <p
              class="text-base lg:text-xl xl:text-2xl text-gray-500 font-bahnschrift-semiLight mb-6"
            >
              Lisha Kaku
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              福岡オフィスアドバイザー
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              株式会社チームワールド代表取締役社長
            </p>
          </div>
          <div class="text-left min-w-40 ml-6 md:ml-0">
            <div class="square3 star-item-bg-red mb-5"></div>
            <p
              class="lg:text-xl xl:text-2xl text-gray-500 font-bahnschrift-semiLight mb-6"
            >
              Ayako Ueda
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              福岡オフィスアドバイザー
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              株式会社チームワールド取締役
            </p>
          </div>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-4 justify-items-start md:justify-items-center"
        >
          <div class="text-left min-w-40 ml-6 md:ml-0">
            <div class="square3 star-item-bg-red mb-5"></div>
            <p
              class="lg:text-xl xl:text-2xl text-gray-500 font-bahnschrift-semiLight mb-6"
            >
              Mei Oda
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              大分オフィスマネージャー
            </p>
            <p class="text-xs leading-7 xl:leading-9 font-kosugi-gothic">
              el próximo paso代表
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------------------------------------------------- -->
    <div class="w-full relative overflow-hidden top-area-2-h mb-32">
      <img
        src="@/assets/top2.png"
        alt="Background"
        class="absolute w-auto z-30 top-area-2-img sm:flex hidden"
      />
      <div class="relative w-full lg:w-5/6 overflow-hidden">
        <div class="relative w-full top-area-2-h2">
          <img
            src="@/assets/door.jpg"
            alt="Background"
            class="absolute top-1/2 left-1/2 transform opacity-60 top-area-2-h2 -translate-x-1/2 -translate-y-1/2 h-auto max-w-none"
            style="width: 120%"
          />
          <div
            class="tracking-widest absolute top-0 left-0 w-full top-area-2-h2 bg-gray-400 bg-opacity-90 sm:flex sm:justify-left items-center text-black space-y-4"
          >
            <div>
              <img
                src="@/assets/3.png"
                alt="Icon"
                class="h-16 xl:h-24 mx-auto sm:mx-16 sm:my-0 my-6"
              />
            </div>
            <div
              class="text-gray-700 sm:ml-0 ml-10"
              style="letter-spacing: 0.1em"
            >
              <h1
                class="lg:text-2xl xl:text-3xl font-bold mb-8"
                style="letter-spacing: 0.12em"
              >
                あなたの「決心」を「決断」に
              </h1>
              <p class="text-xs lg:text-sm leading-5 lg:leading-7">
                置かれている状況や悩みは人それぞれです。
              </p>
              <p class="text-xs lg:text-sm leading-5 lg:leading-7">
                私たちは一人ひとりのお話しに丁寧に耳を傾け、
              </p>
              <p class="text-xs lg:text-sm leading-5 lg:leading-7">
                行動へアプローチできるよう<span class="inline sm:block"
                  >サポートいたします。</span
                >
              </p>
              <p class="text-xs lg:text-sm leading-5 lg:leading-7">
                まずはお問い合わせください。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------------------------------------------------------------------------------- -->
    <!-- <div class="relative w-full">
      <img
        src="@/assets/iStock-1138350098.jpg"
        class="w-full h-full object-cover absolute z-0"
      />
      <div class="w-full py-16 relative z-10 bg-black bg-opacity-70">
        <div
          v-for="item in items"
          :key="item.id"
          class="md:flex items-center md:justify-around py-12 text-white md:ml-0 ml-10"
        >
          <h2
            class="text-base sm:text-xl md:text-base lg:text-xl xl:text-2xl min-w-80 font-montserrat-light"
          >
            {{ item.title }}
          </h2>
          <div
            class="text-xs sm:text-sm md:text-xs lg:text-sm xl:text-base text-left md:text-right mt-4 md:mt-0"
          >
            <p>{{ item.text1 }}</p>
            <p>{{ item.text2 }}</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="back-to-top" @click="scrollToTop">
      <div class="shapes">
        <div class="triangle"></div>
        <div class="rectangle-top"></div>
      </div>
      <div class="text font-bold">BACK<br />TO<br />TOP</div>
    </div>
    <Footter />
    <div class="mouse-circle text-sm" id="mouseCircle">Read More</div>
  </div>
</template>

<script>
import TopNavBar from "../components/TopNavBar";
import Footter from "../components/footter";
// import ScrollingImages from "../components/ScrollingImages";
import ImageComponent from "../components/3DImageComponent";
import { gsap } from "gsap";
import { onMounted, ref } from "vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imageSrc from "@/assets/iStock-863607936.jpg";
import imageSrc2 from "@/assets/iStock-1366837055.jpg";
import imageSrc3 from "@/assets/iStock-1461546768.jpg";
import imageSrc4 from "@/assets/iStock-1187099771.jpg";
import imageSrc5 from "@/assets/iStock-627582672.jpg";
import imageSrc6 from "@/assets/iStock-936677622.jpg";
import imageSrc7 from "@/assets/iStock-694725410.jpg";
import imageSrc8 from "@/assets/iStock-1447053547.jpg";
import imageSrc9 from "@/assets/iStock-1214659701.jpg";
gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    TopNavBar,
    Footter,
    // ImageComponent,
    // ScrollingImages,
  },
  setup() {
    const backToTop = ref(null);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };
    const top = ref(0);
    // const items = ref([
    //   {
    //     id: 1,
    //     title: "HEARING",
    //     text1: "こちらのコンテンツ内容を",
    //     text2: "アンカーリンク",
    //   },
    //   {
    //     id: 2,
    //     title: "PLAN MAKING",
    //     text1: "こちらのコンテンツ内容を",
    //     text2: "アンカーリンク",
    //   },
    //   {
    //     id: 3,
    //     title: "INDEPENDENT SUPPORT",
    //     text1: "こちらのコンテンツ内容を",
    //     text2: "アンカーリンク",
    //   },
    //   {
    //     id: 4,
    //     title: "AFTER SUPPORT",
    //     text1: "こちらのコンテンツ内容を",
    //     text2: "アンカーリンク",
    //   },
    // ]);
    const targetInnerWidth = -0.5 * window.innerWidth;

    const navigateToService = (index) => {
      window.location.href = `/service?section=${index}`;
    };

    onMounted(() => {
      const conceptElement = document.getElementById("concept");
      const mouseCircle = document.getElementById("mouseCircle");
      const targets = document.querySelectorAll('[class*="mouseMove"]');

      targets.forEach((target) => {
        target.addEventListener("mousemove", (e) => {
          mouseCircle.style.opacity = 1;
          mouseCircle.style.top = e.clientY + "px";
          mouseCircle.style.left = e.clientX + "px";
        });

        target.addEventListener("mouseenter", () => {
          target.style.backgroundColor = "#000000";
          const p = target.querySelector("p");
          const originalImg = target.querySelector(".original-icon");
          const whiteImg = target.querySelector(".white-icon");

          if (p) p.style.color = "#fff";
          if (originalImg) originalImg.style.display = "none";
          if (whiteImg) whiteImg.style.display = "block";

          mouseCircle.style.opacity = 1;
          mouseCircle.style.transform = "scale(1)";
        });

        target.addEventListener("mouseleave", () => {
          target.style.backgroundColor = "";
          const p = target.querySelector("p");
          const originalImg = target.querySelector(".original-icon");
          const whiteImg = target.querySelector(".white-icon");

          if (p) p.style.color = "";
          if (originalImg) originalImg.style.display = "block";
          if (whiteImg) whiteImg.style.display = "none";

          mouseCircle.style.opacity = 0;
          mouseCircle.style.transform = "scale(0)";
        });
      });

      ScrollTrigger.create({
        trigger: conceptElement,
        start: "top center",
        end: "bottom center",
        onEnter: () => {
          const backToTopBtn = document.querySelector(".back-to-top");
          if (backToTopBtn) {
            // backToTopBtn.style.display = "flex";
            backToTopBtn.style.opacity = 100;
          }
        },
        onLeaveBack: () => {
          const backToTopBtn = document.querySelector(".back-to-top");
          if (backToTopBtn) {
            // backToTopBtn.style.display = "none";
            backToTopBtn.style.opacity = 0;
          }
        },
      });
      const rectangles = {
        topLeft: document.querySelector(".top-left"),
        topRight: document.querySelector(".top-right"),
        bottomLeft: document.querySelector(".bottom-left"),
        bottomRight: document.querySelector(".bottom-right"),
      };

      // topLeft
      gsap.fromTo(
        rectangles.topLeft,
        {
          borderBottomRightRadius: "10% 30%",
        },
        {
          borderBottomRightRadius: "100% 100%",
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ".animated-section",
            start: "top+=40% bottom",
            end: "bottom bottom",
            scrub: true,
          },
        }
      );

      gsap.to(rectangles.topLeft, {
        left: targetInnerWidth + "px",
        top: -400 + "px",
        duration: 2,
        ease: "none",
        scrub: true,
        scrollTrigger: {
          trigger: ".animated-section2",
          start: "top-=30% bottom",
          end: "top+=5% bottom",
          scrub: true,
        },
      });

      // topRight
      gsap.fromTo(
        rectangles.topRight,
        {
          borderBottomLeftRadius: "10% 30%",
        },
        {
          borderBottomLeftRadius: "100% 100%",
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ".animated-section",
            start: "top+=40% bottom",
            end: "bottom bottom",
            scrub: true,
          },
        }
      );

      gsap.to(rectangles.topRight, {
        right: targetInnerWidth + "px",
        top: -400 + "px",
        duration: 2,
        ease: "none",
        scrub: true,
        scrollTrigger: {
          trigger: ".animated-section2",
          start: "top-=30% bottom",
          end: "top+=5% bottom",
          scrub: true,
        },
      });

      // bottomLeft
      gsap.fromTo(
        rectangles.bottomLeft,
        {
          borderTopRightRadius: "10% 30%",
        },
        {
          borderTopRightRadius: "100% 100%",
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ".animated-section",
            start: "top+=40% bottom",
            end: "bottom bottom",
            scrub: true,
          },
        }
      );

      gsap.to(rectangles.bottomLeft, {
        left: targetInnerWidth + "px",
        bottom: -400 + "px",
        duration: 2,
        ease: "none",
        scrub: true,
        scrollTrigger: {
          trigger: ".animated-section2",
          start: "top-=30% bottom",
          end: "top+=5% bottom",
          scrub: true,
        },
      });

      // bottomRight
      gsap.fromTo(
        rectangles.bottomRight,
        {
          borderTopLeftRadius: "10% 30%",
        },
        {
          borderTopLeftRadius: "100% 100%",
          duration: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ".animated-section",
            start: "top+=40% bottom",
            end: "bottom bottom",
            scrub: true,
          },
        }
      );

      gsap.to(rectangles.bottomRight, {
        right: targetInnerWidth + "px",
        bottom: -400 + "px",
        duration: 2,
        ease: "none",
        scrub: true,
        scrollTrigger: {
          trigger: ".animated-section2",
          start: "top-=30% bottom",
          end: "top+=5% bottom",
          scrub: true,
        },
      });
    });

    return {
      imageSrc,
      imageSrc2,
      imageSrc3,
      imageSrc4,
      imageSrc5,
      imageSrc6,
      imageSrc7,
      imageSrc8,
      imageSrc9,
      top,
      // items,
      scrollToTop,
      navigateToService,
    };
  },
};
</script>
<style scoped>
.ls {
  letter-spacing: 0.1em;
}
.rectangle-all {
  height: 700px;
}
.rectangle {
  position: absolute;
  width: 50%;
  height: 350px;
}
.top-left {
  top: 0;
  left: 0;
}
.top-right {
  top: 0;
  right: 0;
}
.bottom-left {
  bottom: 0;
  left: 0;
}
.bottom-right {
  bottom: 0;
  right: 0;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* 根据需要调整图标大小和位置 */
}
.box {
  height: 300px;
  /* width: 50%; */
}

.left-part {
  min-width: 70%;
}
.min-w-40 {
  width: 40%;
  min-width: 260px;
}
.back-to-top {
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  padding: 10px;
  z-index: 900;
  opacity: 0;
}
.shapes {
  margin-bottom: 4px;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 16px solid black;
}
.rectangle-top {
  width: 30px;
  height: 16px;
  background-color: black;
  margin-top: 2px;
}
.text {
  font-size: 10px;
  line-height: 1.2;
  color: black;
}
.mouse-circle {
  margin-left: -50px;
  margin-top: -50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgba(159, 159, 159, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  pointer-events: none;
  opacity: 0;
  z-index: 9999;
  transform: scale(0);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.top-area-1-img {
  height: 600px;
  top: 0px;
  left: -200px;
}

.top-area-2-img {
  height: 560px;
  top: 100px;
  right: -300px;
}

.top-area-2-h {
  height: 820px;
}

.top-area-2-h2 {
  height: 700px;
}

@media (max-width: 1024px) {
  .top-area-1-img {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin: 20px auto 0;
  }
  .top-area-2-img {
    height: 280px;
    top: 30px;
    right: -280px;
  }
  .top-area-2-h {
    height: 400px;
  }
  .top-area-2-h2 {
    height: 400px;
  }
}

@media (min-width: 1025px) and (max-width: 1320px) {
  .top-area-1-img {
    height: 410px;
    top: 0px;
    left: -200px;
  }
  .top-area-2-img {
    height: 480px;
    top: 30px;
    right: -280px;
  }
  .top-area-2-h {
    height: 520px;
  }
  .top-area-2-h2 {
    height: 520px;
  }
}

@media (min-width: 1321px) {
  .top-area-1-img {
    height: 600px;
    top: 0px;
    left: -200px;
  }
  .top-area-2-img {
    height: 560px;
    top: 100px;
    right: -300px;
  }
  .top-area-2-h {
    height: 820px;
  }
  .top-area-2-h2 {
    height: 700px;
  }
}

.square {
  width: 160px;
  height: 160px;
}

.square2 {
  width: 5px;
  height: 140px;
}
.square3 {
  width: 40px;
  height: 5px;
}
.text-vertical {
  transform: rotate(90deg);
  transform-origin: right top;
  white-space: nowrap;
  margin-top: 140px;
}

.text-vertical2 {
  transform: rotate(-90deg);
  transform-origin: left top;
  white-space: nowrap;
  margin-top: 140px;
}

.text-vertical2 {
  transform: rotate(-90deg);
  transform-origin: left top;
  white-space: nowrap;
  margin-top: 250px;
}
@media (max-width: 1024px) {
  .square {
    width: 160px;
    height: 160px;
  }

  .square2 {
    width: 5px;
    height: 140px;
  }
  .square3 {
    width: 40px;
    height: 5px;
  }
  .text-vertical {
    transform: rotate(90deg);
    transform-origin: right top;
    white-space: nowrap;
    margin-top: 140px;
  }

  .text-vertical2 {
    transform: rotate(-90deg);
    transform-origin: left top;
    white-space: nowrap;
    margin-top: 140px;
  }
}

@media (min-width: 1025px) and (max-width: 1320px) {
  .square {
    width: 200px;
    height: 200px;
  }

  .square2 {
    width: 5px;
    height: 200px;
  }
  .square3 {
    width: 40px;
    height: 5px;
  }
  .text-vertical {
    transform: rotate(90deg);
    transform-origin: right top;
    white-space: nowrap;
    margin-top: 160px;
  }

  .text-vertical2 {
    transform: rotate(-90deg);
    transform-origin: left top;
    white-space: nowrap;
    margin-top: 200px;
  }
}

@media (min-width: 1321px) {
  .square {
    width: 250px;
    height: 250px;
  }

  .square2 {
    width: 7px;
    height: 250px;
  }
  .square3 {
    width: 40px;
    height: 7px;
  }
  .text-vertical {
    transform: rotate(90deg);
    transform-origin: right top;
    white-space: nowrap;
    margin-top: 176px;
  }

  .text-vertical2 {
    transform: rotate(-90deg);
    transform-origin: left top;
    white-space: nowrap;
    margin-top: 250px;
  }
}
.img-l {
  background-image: url("../assets/p.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -50px center;
}
</style>
