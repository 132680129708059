<template>
  <div
    class="fixed top-0 w-full flex items-center justify-around h-14 px-4 sm:px-4 md:px-10 lg:px-14 xl:px-32 bg-gray-100 text-gray-500 z-50 font-bahnschrift-semiLight"
    style="z-index: 1000"
  >
    <div class="flex-1 hidden sm:flex">
      <span
        class="text-xs sm:text-xs md:text-sm lg:text-base font-bold font-tw-cen-mt-bold"
        style="letter-spacing: 0.1px"
        >Inspire for your life</span
      >
    </div>

    <router-link to="/" class="flex-1 text-center">
      <img
        src="@/assets/STAR_ITEM_logo.png"
        class="text-gray-500 h-3 sm:h-3 md:h-3 lg:h-4 mr-auto sm:ml-auto"
        alt="Logo"
      />
    </router-link>

    <div
      class="flex-1 justify-end space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-16 hidden sm:flex"
      style="letter-spacing: 0.1px"
    >
      <router-link
        to="/service"
        class="text-sm md:text-sm lg:text-base"
        style="letter-spacing: 0.1px"
        >service</router-link
      >
      <router-link
        to="/about"
        class="text-sm md:text-sm lg:text-base"
        style="letter-spacing: 0.1px"
        >about us</router-link
      >
      <router-link
        to="/contact"
        class="text-sm md:text-sm lg:text-base"
        style="letter-spacing: 0.1px"
        >contact</router-link
      >
    </div>
    <button @click="toggleMenu" class="sm:hidden">
      <font-awesome-icon :icon="['fas', 'bars']" class="h-5 w-5" />
    </button>
    <!-- 使用绝对定位的下拉菜单 -->
    <div
      v-if="isMenuOpen"
      class="absolute top-full right-0 bg-white shadow-md mt-1 sm:hidden text-sm"
    >
      <router-link
        to="/service"
        class="block px-4 py-3 text-gray-700 hover:bg-gray-100"
        >Service</router-link
      >
      <router-link
        to="/about"
        class="block px-4 py-3 text-gray-700 hover:bg-gray-100"
        >About Us</router-link
      >
      <router-link
        to="/contact"
        class="block px-4 py-3 text-gray-700 hover:bg-gray-100"
        >Contact</router-link
      >
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

export default {
  name: "TopNavBar",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>
