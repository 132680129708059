<template>
  <div
    class="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-gray-100"
    style="z-index: 2000"
    v-show="show"
  >
    <img
      src="@/assets/STAR_ITEM_logo.png"
      alt="Logo"
      class="mb-4 w-40 md:w-64"
    />
    <div class="w-64 h-1 bg-gray-300">
      <div class="progress-bar h-full bg-black" ref="progressBar"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import gsap from "gsap";

const show = ref(true);
const progressBar = ref(null);

onMounted(() => {
  gsap.fromTo(
    progressBar.value,
    { width: "0%" },
    {
      width: "100%",
      duration: 0.7,
      ease: "linear",
      onComplete: () => {
        // 动画完成后隐藏加载页面
        show.value = false;
      },
    }
  );
});
</script>

<style scoped>
.progress-bar {
  width: 0; /* 初始宽度设置为0 */
}
</style>
