<template>
  <div class="w-full bg-center relative pt-32 bg-footter footter-h">
    <div class="bg-black bg-opacity-70 absolute top-0 z-10 w-full h-full"></div>
    <div class="w-full h-full absolute top-0 z-20 pt-32">
      <div class="mx-auto px-4 text-white">
        <div class="flex flex-col items-center" style="letter-spacing: 0.1px">
          <img
            src="@/assets/STAR_ITEM_logo-w.png"
            alt="STAR ITEM Logo"
            class="w-52 lg:w-64 xl:w-80 mb-16"
          />
          <div class="md:flex md:justify-center w-full mb-12">
            <router-link
              to="/service"
              class="py-3 w-44 mx-16 text-center text-base font-bahnschrift-semiLight"
            >
              <p>service</p>
            </router-link>
            <router-link
              to="/contact"
              class="py-3 w-44 lg:mx-10 block mx-auto xl:mx-16 border border-white text-center text-base font-bahnschrift-semiLight"
            >
              <p>contact</p>
            </router-link>
            <router-link
              to="/about"
              class="py-3 w-44 mx-16 text-center text-base font-bahnschrift-semiLight"
            >
              <p>about us</p>
            </router-link>
          </div>
          <div class="text-white text-base mb-1">
            MAIL: info@@staritem.co.jp
          </div>
          <div class="text-white text-xs mb-16">
            ※送信の際は「@」を1つ削除して送信してください
          </div>
          <div class="text-white text-xs">
            Copyright © STAR ITEM CO., LTD. All Right Reserved.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  // Component logic here
};
</script>
<style scoped>
.bg-footter {
  background-image: url("../assets/iStock-1138350098.jpg");
}
@media (min-width: 766px) {
  .footter-h {
    height: 600px;
  }
}
@media (max-width: 765px) {
  .footter-h {
    height: 700px;
  }
}
</style>
