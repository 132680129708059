<template>
  <div v-if="showSplash" class="splash-screen relative">
    <div
      v-show="showTitle"
      class="title text-base md:text-4xl font-bold font-tw-cen-mt-bold"
    >
      Inspire for your life
    </div>
    <div
      class="absolute top-0 w-full logo flex items-center justify-around h-14 px-4 sm:px-10 md:px-16 lg:px-24 xl:px-40 z-50"
      style="z-index: 1000"
    >
      <div class="flex-1 text-center">
        <img
          src="@/assets/STAR_ITEM_logo.png"
          class="h-3 sm:h-3 md:h-3 lg:h-4 mr-auto ml-auto"
          alt="Logo"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import gsap from "gsap";

const showSplash = ref(true);
const showTitle = ref(true);
const showLogo = ref(false);

onMounted(() => {
  if (sessionStorage.getItem("visited")) {
    showSplash.value = false;
  } else {
    sessionStorage.setItem("visited", "true");
    const titleElement = document.querySelector(".title");
    if (titleElement) {
      const targetY =
        20 - titleElement.getBoundingClientRect().top + window.scrollY;
      gsap
        .to(titleElement, {
          opacity: 1,
          duration: 2,
          ease: "power1.inOut",
          onComplete: () => {
            titleElement.style.opacity = 1;
          },
        })
        .then(() => {
          gsap
            .to(titleElement, {
              duration: 2,
              x: () => 100 - titleElement.getBoundingClientRect().left,
              y: targetY,
              ease: "power1.inOut",
            })
            .then(() => {
              gsap
                .to(titleElement, {
                  opacity: 0,
                  duration: 1,
                  ease: "power1.inOut",
                })
                .then(() => {
                  showTitle.value = false;
                  showLogo.value = true;
                  gsap
                    .to(".logo", {
                      opacity: 1,
                      duration: 1,
                      ease: "power1.inOut",
                    })
                    .then(() => {
                      setTimeout(() => {
                        showSplash.value = false;
                      }, 1000);
                    });
                });
            });
        });
    }
  }
});
</script>

<style scoped>
.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
}
.title {
  opacity: 0;
}
.logo {
  opacity: 0;
}
</style>
