// store/index.ts
import { createStore } from "vuex";

interface State {
  isLoading: boolean;
}

export default createStore<State>({
  state: {
    isLoading: false,
  },
  mutations: {
    setLoading(state, status: boolean) {
      state.isLoading = status;
    },
  },
});
