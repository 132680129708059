<template>
  <SplashScreen v-if="showSplashScreen" />
  <LoadingScreen v-else-if="isLoading" />
  <router-view v-else />
</template>

<script>
import { mapState } from "vuex";
import LoadingScreen from "./components/LoadingScreen.vue";
import SplashScreen from "./components/SplashScreen.vue";
import { ref, onMounted } from "vue";

export default {
  components: {
    LoadingScreen,
    SplashScreen,
  },
  setup() {
    const showSplashScreen = ref(false);

    onMounted(() => {
      if (!sessionStorage.getItem("hasVisited")) {
        showSplashScreen.value = true;
        sessionStorage.setItem("hasVisited", "true");
        setTimeout(() => {
          showSplashScreen.value = false;
        }, 7000);
      }
    });

    return {
      showSplashScreen,
    };
  },
  computed: {
    ...mapState(["isLoading"]),
  },
};
</script>
